@import "../../Autobopr/Assets/Functions.less";
@import (inline) "../../Autobopr/Assets/Utilities.css";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@tablet-only: ~"(min-width: 768px) and (max-width: 1199px)";
@desktop: ~"(min-width: 1200px)";
@mobileNavColor: #f40000;
@castleDetailsColor: #0940c9;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/027/";
@cloudUrlNoAuto: "https://files.bookingonline.co.uk/image/upload/themes/027/";


/*#region Fonts*/
.Lato, .BodyFont {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
}

.LatoBold {
	font-family: 'Lato', sans-serif;
	font-weight: 700 !important;
}

.PTSansNarrowBold {
	font-family: 'PT Sans Narrow', sans-serif;
	font-weight: 700;
}

.font-KomikaTitle;

.Komika {
	font-family: 'Komikatitle', sans-serif;
	font-weight: 400;
}

.font-Grobold;

.Grobold {
	font-family: 'Grobold', sans-serif;
	font-weight: normal;
}

/*#endregion Fonts*/
#BodyContent {
	padding-top: 20px;

	@media @tablet {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.textContent {
	padding: 0;

	@media @mobile {
		text-align: center;
	}

	h1 {
		color: #0e63dd;
		.section-title;
		text-shadow: none;
		text-align: left;
		font-size: 2.2rem;

		@media @mobile {
			text-align: center;
			margin-top: 0;
		}
	}
}

.lower-content h2 {
	color: #0e63dd;
	.section-title;
	text-shadow: none;
	text-align: left;
	font-size: 2.2rem;

	@media @mobile {
		text-align: center;
	}
}

.section-title {
	.Grobold;
	font-size: 1.5rem;
	text-transform: uppercase;
	text-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2);
	text-align: center;

	@media @tablet {
		font-size: 2rem;
	}

	@media @desktop {
		font-size: 3rem;
	}
}

.nicefont {
	.BodyFont;
}

h1, h2, h3 {
	.Grobold;
}

form {
	margin: 0 auto;
	overflow: hidden;
	position: relative;
}

html {
	font-size: 16px;
}

body {
	.BodyFont;
	background: #fff;
}

.textContent {
	font-size: 1rem;
}

.outer-wrapper {
	overflow: hidden;
	max-width: 100vw;
	position: relative;
	.Gradient(#0491ff,#d3e3ec);

	&.outer-wrapper--alt {
		.columns {
			margin-bottom: 50px;

			&--top {
				.columns__main {
					display: block;
				}
			}
		}

		.left-social-feeds-alt {
			display: block;
		}

		.ribbon__container {
			transform: translateX(-50%) translateY(50%);
			bottom: 0;
		}

		@media @desktop {
			.columns__main, .columns__left {
				padding-top: 30px;
			}
		}
	}
}

.productText {
	@media @mobile {
		width: auto;
		float: right;
		margin-top: 10px;
	}

	@media @tablet {
		width: auto;
	}
}

.products-intro {
	display: block;
	margin: 0 auto 30px;
}

#PageListings, .homepage-listings-row {
	& > div {
		@media (max-width:420px) {
			width: 100%;
		}
	}
}

.container {
	position: relative;

	@media @mobile {
		margin: 0 auto 0;
		padding: 5px;
	}

	@media @tablet {
		width: 100%;
		padding: 0 20px;
		margin: 0 auto 0;
	}

	@media @desktop {
		width: 1170px;
		margin: 0 auto 0;
	}
}
/*#region TopLinksMobile*/
#TopLinksMobileSpacer {
	padding-top: 53px;
}

#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();
	min-height: 5px;

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	@media @sm {
		display: block !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0% 0%;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion TopLinksMobile*/
.top-wrapper {
	position: relative;
}

.header {
	@width: 1170px;
	@height: 359px;
	text-align: center;
	position: relative;

	@media @mobile {
		padding: 30px 15px;
	}

	@media @tablet {
		padding-top: round(percentage(@height/@width),2);
	}

	&__rainbow {
		.TopBarItem(786,@width,@height,none,-120,left,200);
	}

	&__logo {
		z-index: 3;

		@media @tablet {
			position: absolute;
			left: 30.23%;
			width: 35.16%;
			bottom: 6%;
		}

		@media @mobile {
			display: block;
			margin: 0 auto 20px;
			max-width: 400px;
		}
	}

	&__badges {
		@media @mobile {
			margin: 10px auto 0;
		}
	}

	&__bcn {
		.TopBarItem(82,@width,@height,auto,90,right,70);

		@media @mobile {
			height: 58px;
		}
	}

	&__tipe {
		.TopBarItem(57,@width,@height,auto,90,right,0);

		@media @mobile {
			height: 58px;
		}
	}

	&__social-links {
		display: flex;

		@media @mobile {
			justify-content: center;
		}

		@media @tablet {
			position: absolute;
			width: 19.08%;
			bottom: 23.64%;
			right: 13.43%;
			justify-content: flex-end;
		}
	}

	&__social-link {
		border: 2px solid #fff;
		border-radius: 50%;
		margin: 0 5px;
		display: inline-block;

		@media @mobile {
			width: 55px;
		}

		@media @tablet {
			border: 4px solid #fff;
			margin: 0 2%;
			max-width: 32%;
		}
	}

	&__call {
		.TopBarItem(231,@width,@height,auto,254,right,5);

		@media @mobile {
			display: block;
		}
	}

	&__phone {
		.TopBarItem(379,@width,@height,auto,174,right,5);

		@media @mobile {
			display: block;
			margin: 10px auto 0;
			max-width: 400px;
		}
	}

	&__weather {
		position: absolute;
		width: round(percentage(311/@width),2);
		height: round(percentage(211/@height),2);
		left: 1%;
		bottom: 28.6%;
		z-index: 3;

		&:hover {
			.header__weather-sun-corona {
				transform: rotateZ(90deg) scale(1.15);
			}

			.header__weather-sun-body {
				transform: scale(1.2);
			}
		}
	}

	&__weather-bg {
		//edit fill color in svg
		position: absolute;
		bottom: 0;
		left: 0;
		width: 80%;
	}

	&__weather-sun {
		position: absolute;
		right: -2%;
		width: 50%;
		top: 4%;
	}

	&__weather-text {
		position: absolute;
		bottom: 16%;
		left: 5%;
		width: 70%;
		transform-origin: center center;
	}
}

.toplinks {
	border-bottom-right-radius: 15px;
	position: relative;

	&__wrapper {
		.Gradient(#df2222,#ec3a3a);
		position: relative;
		z-index: 10;

		&:before {
			.StickToEdges;
			background-repeat: repeat;
			@supports

			(mix-blend-mode:color-burn) {
				background-image: url("@{cloudUrl}waves-bg.png");
				opacity: 0.6;
				mix-blend-mode: color-burn;
				background-size: 200px;
			}
		}
	}

	&__inner {
		position: relative;
		padding: 0 10px;
		z-index: 4;
	}

	&__ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
	}

	&__li {
		text-align: center;
		list-style: none;
		position: relative;
		.Transition3s();
		flex-grow: 1;

		&.open .dropdown-toggle:focus {
			color: #ffea3f;
		}
	}

	&__link {
		.LatoBold;
		color: #fff;
		background: none;
		font-size: 18px;
		transition: all 0.3s;
		padding: 20px 10px;
		font-size: 1.8vw;
		white-space: nowrap;
		display: block;
		text-transform: uppercase;

		@media @desktop {
			font-size: 18px;
		}

		&:hover, &.active, &:focus {
			background: #0b4fd2;
			color: #fff;
			text-shadow: 2px 2px rgba(0,0,0,0.2);
		}
	}

	&__dropdown {
		background-color: rgba(0,0,0,0.8);
		text-align: center;
		padding: 0;
		background: #de1515;
		min-width: 100%;
	}

	&__dropdown-li {
		display: block;
		width: 100%;
		margin-left: 0;
		border-radius: 0;
	}

	&__dropdown-link {
		padding: 5px 10px !important;
		font-size: 1rem;
		color: #fff !important;
		.LatoBold;
		transition: all 0.3s;

		@media @desktop {
			font-size: 1.2rem;
		}

		&:hover, &:focus {
			background-color: #0b4fd2 !important;
			color: #fff !important;
		}
	}
}

.body-wrapper {
	position: relative;
}

.ribbon {
	position: relative;
	transform: translateY(-56%);
	//  z-index: 1;
	&__container {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		z-index: 2;
		display: none;
		pointer-events: none;

		@media @desktop {
			display: block;
		}
	}

	&:before, &:after {
		content: "";
		position: absolute;
		width: 4%;
		padding-top: 20%;
		background-image: url("@{cloudUrl}ribbon-left.png");
		top: 24%;
		left: -4%;
		background-repeat: no-repeat;
	}

	&:after {
		background-image: url("@{cloudUrl}ribbon-right.png");
		left: auto;
		right: -4%;
		top: 27%;
	}
}

.banner {
	@width: 1170px;
	@rightWidth: 291px;
	@height: 500px;

	@media @mobile {
		border-radius: 10px;
	}

	&__container.us-override .SearchButton {
		background-image: url("@{cloudUrl}search-button-us.png");
	}

	#CarouselContainer {
		.border-left-radius(10px);

		@media @mobile {
			border-radius: 10px;
		}
	}

	&__inner {
		@media @tablet {
			display: flex;
			justify-content: space-around;
		}
	}

	&__gallery {
		background-color: #fff;
		position: absolute;
		.BannerPanelSet(56.8%);
		.border-left-radius(10px);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		@media @mobile {
			position: relative;
		}
	}

	&__left {
		position: relative;

		@media @tablet {
			width: round(percentage(880/@width),2);

			&:before {
				content: "";
				width: 100%;
				padding-top: 56.82%;
				display: block;
			}
		}
	}

	&__left-no-booking {
		position: relative;

		@media @tablet {
			width: 100%;

			&:before {
				content: "";
				width: 100%;
				padding-top: 56.82%;
				display: block;
			}
		}
	}

	&__right {
		@media @mobile {
			padding: 35px 20px;
			text-align: center;
			margin-top: 10px;
			border-radius: 5px;
		}

		@media @tablet {
			width: round(percentage(@rightWidth/@width),2);
		}

		background-color: #00aaff;
		.RadialGradient(#2bb2f5,#0079df);
		.border-right-radius(5px);
		position: relative;
		box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.5);

		&:before {
			background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAA1NJREFUWIXFl9uSozYQhn9hzhgwPsB4Jt6X8MNvqrJbtblIKpVk8xAZn2CMDRh71zaIvUilJTm1t0F3n5BEi+7+u2GfPv64vHz5gn+HxjQwTSNmDNAYI+YdB+ecOPB9BL5PfL3dcL3diNu2Rdu2xLZtw3ZsYr2sSpyqE01MJhNEo4i4KArsDwfi8WSM6WwmDhgMAGEfRqMQtu0Q//36is1mSxyNIwx0XVwYPY/eDdAdx0EYhjRhWRZM0yAOwwC2bRG3LcfxWBAH/hCmPyQ+FiUulx1x0zSIIuHStmmRpplqwHw+VzY0zZ3Yti1o2oA4TTPspQNM00AYBsRFUeJ1tSaOZzPMZlPiLMuQZmJ/7y7o3QD9fm+w26U0YdmW4vO6PuPr1ytxByCORRp2vMNW2s85RyylqeM6im74gQ/TMmUD7silPJ9OJ7CkBfX5jDwXz2ezKeJY+HS3S5ULjMdjxeec/1e4bGl/7y7o3QD28cP7pWGIvOeco5M+maZp0AbCTsuyYFlyjNSo67M4seuUFzyub5o7mrYh1nXdQJIkNJGmKdLsjTh5ShDHMfHtdsP1KoLy8QWH/IA8z4mjKIJti+JTn884Ho/iguh59G4A++vzb8sgEFJaFiXKshQLGJRy63kePM8jLh7WG4YBU4qpf6Rd+FzTmBJTuu/7itZ7ngddF5xlGTJJuxeLBaZTWdvflOLy7t0C82dRW3bbHfb7PXEcq7Whdxf0bgD74/dflqPRiCaqqkJVVcQd5+g6oQtM06BJPWNzb9BKPh5FEaKxep7c8rVti1bSGb0oCgyHoqE4ny/IDyJPp5MxphNRXLbbHVbbFfFTkmD+JHRENwx0khh5rgtb0on1Zov1ekPcuwt6N4D9/OmnJZMSvX0on67rwHNFm32qTjidTuohkk4EYYhAatGqskIpxRQDU9brlmVi9Sp6uCiKEEUiiBiD8mPhuI7SsKRZhnQndKADg+u6xEVRYi39Fyx+eMHLyzNx7y7o3QD2+c9flwcp7TreKXnveR6GQ6H99alGXdffPbDrOnApDbuH52EYYCTFiG4YpvJjkue5ot0JY/AlnbhcLnh7k/qFJEYi9Qur9QYrKc/nz3PM50/EA00Db6WG57tX+Z9G7wZ8A0IRaqkFLAytAAAAAElFTkSuQmCC");
			.StickToEdges;
			background-repeat: repeat;
			background-size: 20px;
			opacity: 0.7;
			.border-right-radius(5px);
		}

		#PostcodeSearchLeft select, #PostcodeSearchLeft input {
			border-radius: 10px;

			@media @mobile {
				position: relative;
				margin: 0 auto 15px;
				height: 45px;
				border-radius: 5px;
				text-align: center;
				width: 240px;
			}

			@media @tablet {
				padding-left: 10%;
				position: absolute;
				top: round(percentage(116/@height),2);
				height: round(percentage(41/@height),2);
				left: round(percentage(67/@rightWidth),2);
				width: round(percentage(211/@rightWidth),2);
			}
		}

		.PostcodeMessage {
			z-index: 10;
			position: fixed;
			top: 50px;
			bottom: auto;

			@media @sm {
				top: 0;
			}
		}

		#PostcodeSearchRight a {
			@media @mobile {
				height: 48px;
				background: #f40000;
				.Gradient(#ff3e3e,#ff0000);
				border-radius: 5px;
				color: #fff;
				.FlexCenter();
				.LatoBold;
				font-size: 1.2rem;
				border: 1px solid transparent;
				position: relative;
				width: 240px;
				margin: 0 auto 20px;

				&:hover, &:focus {
					color: #fff;
				}

				span {
				}
			}
		}

		.SearchDate, .SearchCategory {
			border-radius: 10px;
			border: none;
			z-index: 1;

			@media @tablet {
				width: round(percentage(241/@rightWidth),2);
				.TransformMiddleH;
				height: round(percentage(41/@height),2);
			}
		}

		.SearchDate {
			padding-left: 10px;

			@media @mobile {
				display: block;
				position: relative;
				margin: 0 auto 15px;
				border-radius: 5px;
				height: 45px;
				width: 240px;
				text-align: center;
			}

			@media @tablet {
				top: round(percentage(230/@height),2);
			}
		}

		.SearchCategory {
			@media @mobile {
				display: block;
				position: relative;
				margin: 0 auto 15px;
				border-radius: 5px;
				height: 45px;
				width: 240px;
				text-align: center;
			}

			@media @tablet {
				top: round(percentage(280/@height),2);
			}
		}

		.SearchButton {
			background-image: url("@{cloudUrl}search-button.png");
			background-size: 100% 100%;
			font-size: 0;

			@media @mobile {
				position: relative;
				margin: 0 auto;
				width: 200px;
			}

			@media @tablet {
				position: absolute;
				top: round(percentage(330/@height),2);
				height: round(percentage(41/@height),2);
				width: round(percentage(241/@rightWidth),2);
				.TransformMiddleH;
			}
		}
	}

	&__book-online {
		@media @mobile {
			display: block;
			position: relative;
			margin: 0 auto 15px;
		}

		@media @tablet {
			width: round(percentage(242/@rightWidth),2);
			.TransformMiddleH;
			top: round(percentage(43/@height),2);
		}
	}

	&__map-icon {
		position: absolute;
		width: 29.55%;
		left: 12px;
		top: 16.1%;
		z-index: 1;

		@media @mobile {
			display: none;
		}
	}

	&__check-area {
		@media @mobile {
			display: block;
			position: relative;
			margin: 0 auto 15px;
		}

		@media @tablet {
			position: absolute;
			width: round(percentage(177/@rightWidth),2);
			left: round(percentage(99/@rightWidth),2);
			top: round(percentage(85/@height),2);
		}
	}

	&__check-availability {
		@media @mobile {
			position: relative;
			margin: 0 auto 15px;
		}

		@media @tablet {
			position: absolute;
			width: round(percentage(234/@rightWidth),2);
			top: round(percentage(183/@height),2);
			.TransformMiddleH;
		}
	}

	&__weather {
		position: absolute;
		width: 82.76%;
		height: 21.2%;
		left: 8.9%;
		z-index: 3;
		top: 76.6%;
		z-index: 3;

		&:hover {
			.banner__weather-sun-corona {
				transform: rotateZ(90deg) scale(1.15);
			}

			.banner__weather-sun-body {
				transform: scale(1.2);
			}
		}
	}

	&__weather-bg {
		//edit fill color in svg
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__weather-sun-corona {
		position: absolute;
		top: 1%;
		left: 2%;
		width: 44%;
		transition: all 3s ease-in-out;
	}

	&__weather-sun-body {
		position: absolute;
		width: 33%;
		top: 14%;
		left: 8%;
		transition: all 3s ease-in-out;
		transform-origin: center center;
	}

	&__weather-text {
		position: absolute;
		bottom: 25%;
		right: 7%;
		width: 41%;
		transform-origin: center center;
	}
}

.featured-categories {
	margin: 20px auto 10px;

	@media @mobile {
	}

	&__col {
		@media (max-width:420px) {
			width: 100%;
		}

		@media (max-width:600px) {
			width: 50%;
		}
	}

	&__image-panel {
		padding-top: 79.333%;
		position: relative;
	}

	img {
		width: 100%;
		max-width: none !important;
		transition: opacity 0.3s;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		object-fit: cover;
	}

	&__category-panel {
		position: relative;
		margin-bottom: 10px;
		display: block;
	}
}

.columns {
	display: flex;
	box-shadow: 0 5px 25px 0 rgba(0,0,0,0.2);
	border-radius: 15px;
	position: relative;
	z-index: 1;
	margin-top: 30px;
	@columnsWidth: 1170px;
	@leftColWidth: 302;
	@rightColWidth: 871px;

	&--top {
		margin-top: 0;

		.columns__main {
			display: flex;
			flex-direction: column;
			padding-bottom: 30px;
		}

		.columns__left {
			padding-bottom: 30px;

			&:after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				width: 10px;
				.HorizontalGradient(rgba(0,0,0,0),rgba(0,0,0,0.05));
			}
		}
	}

	&__left {
		background: #fff;
		position: relative;
		width: round(percentage(@leftColWidth/@columnsWidth),2);
		z-index: 2;
		border-bottom-left-radius: 15px;
		.border-left-radius(15px);
		padding: 0 20px 0 15px;
		display: flex;
		flex-direction: column;

		&.columns__left--home-listings {
			padding: 0 20px 0 0;
		}
	}

	&__main {
		overflow: hidden;
		background: #fff;
		position: relative;
		background-size: 100% auto;
		background-position: 0 5px;
		width: 100%;
		border-bottom-right-radius: 15px;
		padding: 0 15px;
		z-index: 2;
		.border-right-radius(15px);

		@media @mobile {
			padding: 0 10px;
			margin: 0;
			border-radius: 15px;
		}

		@media @sm {
			width: round(percentage(@rightColWidth/@columnsWidth),2);
		}
	}

	&__main-offset {
		margin: 0 15px;
		padding: 1px 15px;
		background: #fff;

		@media @mobile {
			padding: 1px 10px;
			margin: 0;
		}

		&:first-child {
			@media @mobile {
			}
		}
	}
}

.category-links {
	margin-bottom: 10px;
	position: relative;

	&__link, &__child-link {
		position: relative;
		display: block;
		margin-top: 5%;
		text-align: left;
		.PTSansNarrowBold;
		.TextShadow1();
		color: white;
		.Transition3s();
		background-size: 100% 100%;
		border-radius: 25px;
		padding: 8px 15px;
		overflow: hidden;

		&:before {
			content: "";
			position: absolute;
			left: -2px;
			top: 50%;
			transform: translateY(-50%);
			border-left: 8px solid #fff;
			border-top: 8px solid transparent;
			border-bottom: 8px solid transparent;
			opacity: 0;
			transition: opacity 0.3s;
		}

		&:after {
			content: "";
			position: absolute;
			border-radius: 25px;
			top: 0;
			left: 0;
			bottom: 40%;
			right: 0;
			transition: all 0.3s;
		}

		&:first-child {
			margin-top: 25px;
		}

		&:nth-of-type(odd) {
			.Gradient(#1175e1,#0940c9);

			&:after {
				background: rgba(255,255,255,0.05);
			}

			+ div .category-links__child-link {
				.Gradient(#1175e1,#0940c9);

				&:after {
					background: rgba(255,255,255,0.05);
				}
			}
		}

		&:nth-of-type(even) {
			.Gradient(#f40000,#eb0000);

			&:after {
				background: rgba(255,255,255,0.2);
			}

			+ div .category-links__child-link {
				.Gradient(#f40000,#eb0000);

				&:after {
					background: rgba(255,255,255,0.2);
				}
			}
		}

		@media @desktop {
			font-size: 20px;
		}

		&:hover, &:focus, &.active {
			color: #fff;
			padding-left:20px;

			&:before{
				opacity:1;
			}
		}
	}

	&__child-link {
		margin-left: 10px;
	}
}
/*#region Other Left Column Stuff*/
.left-block-top {
	padding: 10% 5%;
	.Gradient(#0086ff,#004aff);
	position: relative;
	border-radius: 10px;
	margin-top: auto;

	&:before {
		.StickToEdges;
		background-image: url("@{cloudUrl}left-block-starburst.png");
		background-size: 100% 100%;
	}
}

.left-social-feeds-alt {
	display: none;
}

.left-block-lower {
	padding: 10% 5%;
	.Gradient(#0086ff,#004aff);
	position: relative;
	border-radius: 5px;
	margin-top: 10px;

	&:before {
		.StickToEdges;
		background-image: url("@{cloudUrl}left-block-starburst.png");
		background-size: 100% 100%;
	}
}
/*#endregion Other Left Column Stuff*/
.homepage-listings {
	padding-bottom: 160px;

	&__sidebar {
		background: #fff;
		border-radius: 10px;
		padding: 8px;
	}

	.columns {
		box-shadow: none;
	}

	.columns__left {
		background: transparent;
	}

	.columns__main {
		background: transparent;
	}
}

.newsletter {
	@width: 840px;
	@height: 100px;
	padding-top: 14.3%;
	.Gradient(#0e66df,#0835bd);
	border-radius: 10px;
	position: relative;
	text-align: center;
	margin-top: auto;

	&:before {
		border-radius: 10px;
		.StickToEdges;
		background-repeat: repeat;
		opacity: 0.5;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOAgMAAABiJsVCAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAARnQU1BAACxjwv8YQUAAAABc1JHQgCuzhzpAAAADFBMVEUAAAAAAAAAAAAAAAA16TeWAAAABHRSTlMADQcaCWiadAAAAChJREFUCNdjuMDAIMAQxsCgwCDLAASqCUCCESjIwBQG4hMWTGBgCgAA8yMHEmDIg7sAAAAASUVORK5CYII=");
	}

	@media @mobile {
		padding: 20px;
	}

	&__text {
		display: block;
		margin: 0 auto;
		position: relative;

		@media @tablet {
			position: absolute;
			width: round(percentage(280/@width),2);
			left: round(percentage(16/@width),2);
			.TransformMiddleV;
			margin-top: 0.5%;
		}
	}

	input {
		border-radius: 5px;
		padding-left: 2%;
		border: none;
		box-shadow: 0 2px 3px rgba(255,255,255,0.5);
		min-height: 40px;
		display: block;
		width: 100%;
		max-width: 410px;
		position: relative;

		@media @mobile {
			text-align: center;
			margin: 20px auto 0;
		}

		@media @tablet {
			position: absolute;
			width: round(percentage(333/@width),2);
			left: round(percentage(321/@width),2);
			.TransformMiddleV;
			height: 45%;
		}
	}

	.NewsletterSubmit {
		.Gradient(#d62c2c,#b20808);
		text-shadow: 0 1px #000;
		border-radius: 10px;
		color: #fff;
		.FlexCenter;
		box-shadow: 0 2px 3px rgba(255,255,255,0.5);
		font-size: 5vw;
		transition: all 0.3s;
		font-size: 0;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 40%;
			border-radius: 10px;
			background: rgba(255,255,255,0.1);
		}

		&:after {
			.StickToEdges;
			background-image: url("@{cloudUrl}subscribe.png");
			background-size: 67%;
		}

		@media @mobile {
			margin-top: 20px;
			padding: 5px 20px;
			display: inline-block;
			width: 215px;
			height: 50px;
		}

		@media @tablet {
			height: 45%;
			position: absolute;
			width: round(percentage(160/@width),2);
			left: round(percentage(661/@width),2);
			.TransformMiddleV;
		}

		&:hover {
			color: #ffe421;
			box-shadow: 0 0 5px rgba(255,255,255,0.5);
		}
	}
}

.testimonials {
	//heights set from theme.js
	background: #0072ff;
	text-align: center;
	position: relative;
	border: 10px solid #fff;
	border-radius: 10px;
	margin-bottom: 50px;
	overflow: hidden;
	padding: 20px 5%;
	box-shadow: inset 0px 0px 10px 10px rgba(0,0,0,0.15);

	@media @desktop {
		padding: 50px 5%;
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAA1NJREFUWIXFl9uSozYQhn9hzhgwPsB4Jt6X8MNvqrJbtblIKpVk8xAZn2CMDRh71zaIvUilJTm1t0F3n5BEi+7+u2GfPv64vHz5gn+HxjQwTSNmDNAYI+YdB+ecOPB9BL5PfL3dcL3diNu2Rdu2xLZtw3ZsYr2sSpyqE01MJhNEo4i4KArsDwfi8WSM6WwmDhgMAGEfRqMQtu0Q//36is1mSxyNIwx0XVwYPY/eDdAdx0EYhjRhWRZM0yAOwwC2bRG3LcfxWBAH/hCmPyQ+FiUulx1x0zSIIuHStmmRpplqwHw+VzY0zZ3Yti1o2oA4TTPspQNM00AYBsRFUeJ1tSaOZzPMZlPiLMuQZmJ/7y7o3QD9fm+w26U0YdmW4vO6PuPr1ytxByCORRp2vMNW2s85RyylqeM6im74gQ/TMmUD7silPJ9OJ7CkBfX5jDwXz2ezKeJY+HS3S5ULjMdjxeec/1e4bGl/7y7o3QD28cP7pWGIvOeco5M+maZp0AbCTsuyYFlyjNSo67M4seuUFzyub5o7mrYh1nXdQJIkNJGmKdLsjTh5ShDHMfHtdsP1KoLy8QWH/IA8z4mjKIJti+JTn884Ho/iguh59G4A++vzb8sgEFJaFiXKshQLGJRy63kePM8jLh7WG4YBU4qpf6Rd+FzTmBJTuu/7itZ7ngddF5xlGTJJuxeLBaZTWdvflOLy7t0C82dRW3bbHfb7PXEcq7Whdxf0bgD74/dflqPRiCaqqkJVVcQd5+g6oQtM06BJPWNzb9BKPh5FEaKxep7c8rVti1bSGb0oCgyHoqE4ny/IDyJPp5MxphNRXLbbHVbbFfFTkmD+JHRENwx0khh5rgtb0on1Zov1ekPcuwt6N4D9/OmnJZMSvX0on67rwHNFm32qTjidTuohkk4EYYhAatGqskIpxRQDU9brlmVi9Sp6uCiKEEUiiBiD8mPhuI7SsKRZhnQndKADg+u6xEVRYi39Fyx+eMHLyzNx7y7o3QD2+c9flwcp7TreKXnveR6GQ6H99alGXdffPbDrOnApDbuH52EYYCTFiG4YpvJjkue5ot0JY/AlnbhcLnh7k/qFJEYi9Qur9QYrKc/nz3PM50/EA00Db6WG57tX+Z9G7wZ8A0IRaqkFLAytAAAAAElFTkSuQmCC') /*little-plusses.png*/;
	}

	&__containers {
		margin-top: -50px;
	}

	&__container {
		margin: 10px;
		background: #fff;
		padding: 15px;
		border-radius: 10px;
		box-shadow: 0 0 27px 10px rgba(0,0,0,0.15);
	}

	&__title {
		position: relative;
		margin-top: 0;

		@media @desktop {
			font-size: 38px;
		}
	}

	&__stars {
		position: relative;
		transform: translateY(-70px);
		z-index: 1;

		&:before, &:after {
			content: "";
			position: absolute;
			width: 30%;
			padding-top: 16%;
			top: 0;
			.BackgroundImg("@{cloudUrl}testimonial-stars", png);
			background-size: 100% 100%;
			z-index: 1;
			background-repeat: no-repeat;
		}

		&:before {
			left: -4%;
		}

		&:after {
			right: -4%;
		}
	}

	&__link {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		height: 100%;
	}

	&__text {
		color: #808080;
		margin-bottom: 10px;
		font-size: 1rem;
	}

	&__author {
		color: #ff007d;
		.Komika;
		padding: 0 10px;
		font-size: 1.25rem;
	}
}
/*#region Castle Panels*/
.castlePanel {
	width: 100%;
	border: none;
	padding: 10px;
	background-color: white;
	border-radius: 5px;
	margin: 0 0 30px;
	text-align: center;
	transition: all 0.3s;
	position: relative;
	border: 2px solid #ccc;

	&:hover {
		.castleCheckBook {
			color: yellow;
		}

		box-shadow: 5px 5px 6px 0 rgba(0,0,0,.075);
	}

	.SingleImagePanel img {
		width: 100%;
		height: 100%;
		top: 0 !important;
		left: 0 !important;
		object-fit: cover;
	}

	.castleTitlePanel {
		margin-top: 15px;
		padding: 0 5%;
		display: flex;
		justify-content: center;
		align-items: center;

		a {
			.LatoBold;
			color: #4c4c4c;
			font-size: 1rem;
			position: relative;
		}
	}

	.castlePriceDefault, .castlePriceDiscounted {
		color: #4c4c4c;
		.LatoBold;
		font-size: 1.2rem;
		position: relative;

		span.oldprice {
			color: #ff0000;
			font-size: 20px;
		}

		span.newprice {
			font-size: 24px;
			color: #4c4c4c;
		}
	}

	.castleCheckBook {
		position: relative;
		border-radius: 3px;
		color: #fff;
		font-size: 18px;
		.Gradient(#eb3636,#d52424);
		text-shadow: 0 1px 1px rgba(0,0,0,.75);
		box-shadow: 0 1px 1px 1px rgba(0,0,0,.2);
		height: auto;
		padding: 10px 20px;
		display: block;
		.Komika;
		text-transform: uppercase;
	}
}
/*#endregion Castle Panels*/
.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: 195px;
		display: inline-block;
		margin: 20px 5px 10px;
		float: none;
	}
}

.home-gallery {
	background: #fff;
	display: block;
	position: relative;
	padding: 20px 0 15px;
	background: transparent;
	@color: #0b4dd0;

	&:before, &:after {
		content: "";
		position: absolute;
		width: ~'calc( (100vw - 1200px)/2)';
		top: 0;
		bottom: 0;
	}

	&:before {
		left: 0;
		z-index: 1;
		.HorizontalGradient(fade(@color,100),fade(@color,0));
	}

	&:after {
		right: 0;
		mask-image: linear-gradient(-90deg,rgba(0,0,0,1) 20%, rgba(0,0,0,0) 100%);
		background-position-x: ~'calc(((100vw - 1200px)/2) - 100vw + 3px)';
		.HorizontalGradient(fade(@color,0),fade(@color,100));
	}

	&__wrapper {
		background-color: @color;
	}

	&__container {
		background: #fff;
	}

	&__slide {
		margin: 0 10px;
	}

	&__link {
		display: block;
		border-radius: 5px;
		overflow: hidden;
		padding: 0;
		margin: 0 10px;
		border: 5px solid #fff !important;
		border-radius: 0 !important;
		background-color: #fff;
		transition: all 0.3s;
		.BoxShadow1;
		padding: 1% 1% 63% !important;

		&:hover {
			border-color: #ababab;
		}

		img {
			left: 0 !important;
			top: 0 !important;
		}
	}
}

.footer {
	&__wrapper {
		background-color: #000;
		color: #fff;
		padding: 25px 10px;
		width: 100%;
		z-index: 1;
		position: relative;

		@media @mobile {
			text-align: center;
		}
	}

	&__links {
		text-align: center;

		@media @mobile {
			text-align: center;
			margin-top: 15px;
		}

		a {
			color: white;
			margin-right: 10px;
		}
	}

	&__BCN {
		display: block;
		max-width: 180px;

		@media @mobile {
			text-align: center;
			display: inline-block;
		}

		@media @sm {
			float: right;
		}

		img {
			margin: 0 auto;
		}
	}
}

#CategoryPagination > li > a {
	width: 42px;
}

/*#region Animations*/
.wow {
	visibility: hidden;
}

.fadeIn2 {
	animation-name: fadeIn2;
	animation-fill-mode: both;
}


.animated {
	animation-duration: 1s;
	animation-fill-mode: both
}

@keyframes fadeIn {
	0% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.fadeIn {
	animation-name: fadeIn
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translate3d(0,100%,0)
	}

	to {
		opacity: 1;
		transform: none
	}
}

.fadeInUp {
	animation-name: fadeInUp
}

@keyframes zoomIn {
	0% {
		opacity: 0;
		transform: scale3d(.3,.3,.3)
	}

	50% {
		opacity: 1
	}
}

.zoomIn {
	animation-name: zoomIn
}

@keyframes bounceIn {
	0%,20%,40%,60%,80%,to {
		animation-timing-function: cubic-bezier(.215,.61,.355,1)
	}

	0% {
		opacity: 0;
		transform: scale3d(.3,.3,.3)
	}

	20% {
		transform: scale3d(1.1,1.1,1.1)
	}

	40% {
		transform: scale3d(.9,.9,.9)
	}

	60% {
		opacity: 1;
		transform: scale3d(1.03,1.03,1.03)
	}

	80% {
		transform: scale3d(.97,.97,.97)
	}

	to {
		opacity: 1;
		transform: scaleX(1)
	}
}

.bounceIn {
	animation-name: bounceIn
}


.animated.bounceIn {
	animation-duration: .75s
}





/*#endregion Animations*/
