/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region Fonts*/
.Lato,
.BodyFont {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}
.LatoBold {
  font-family: 'Lato', sans-serif;
  font-weight: 700 !important;
}
.PTSansNarrowBold {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700;
}
@font-face {
  font-family: 'Komikatitle';
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/komikatitle-webfont.eot');
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/komikatitle-webfont.eot?#iefix') format('embedded-opentype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/komikatitle-webfont.woff2') format('woff2'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/komikatitle-webfont.woff') format('woff'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/komikatitle-webfont.ttf') format('truetype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/komikatitle-webfont.svg#komikatitle-webfont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.Komika {
  font-family: 'Komikatitle', sans-serif;
  font-weight: 400;
}
@font-face {
  font-family: 'Grobold';
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/grobold.eot');
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/grobold.eot?#iefix') format('embedded-opentype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/grobold.woff2') format('woff2'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/grobold.woff') format('woff'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/grobold.ttf') format('truetype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/grobold.svg#grobold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.Grobold {
  font-family: 'Grobold', sans-serif;
  font-weight: normal;
}
/*#endregion Fonts*/
#BodyContent {
  padding-top: 20px;
}
@media (min-width: 768px) {
  #BodyContent {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.textContent {
  padding: 0;
}
@media (max-width: 767px) {
  .textContent {
    text-align: center;
  }
}
.textContent h1 {
  color: #0e63dd;
  font-family: 'Grobold', sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2);
  text-align: center;
  text-shadow: none;
  text-align: left;
  font-size: 2.2rem;
}
@media (min-width: 768px) {
  .textContent h1 {
    font-size: 2rem;
  }
}
@media (min-width: 1200px) {
  .textContent h1 {
    font-size: 3rem;
  }
}
@media (max-width: 767px) {
  .textContent h1 {
    text-align: center;
    margin-top: 0;
  }
}
.lower-content h2 {
  color: #0e63dd;
  font-family: 'Grobold', sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2);
  text-align: center;
  text-shadow: none;
  text-align: left;
  font-size: 2.2rem;
}
@media (min-width: 768px) {
  .lower-content h2 {
    font-size: 2rem;
  }
}
@media (min-width: 1200px) {
  .lower-content h2 {
    font-size: 3rem;
  }
}
@media (max-width: 767px) {
  .lower-content h2 {
    text-align: center;
  }
}
.section-title {
  font-family: 'Grobold', sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-shadow: 0px 4px 9px rgba(0, 0, 0, 0.2);
  text-align: center;
}
@media (min-width: 768px) {
  .section-title {
    font-size: 2rem;
  }
}
@media (min-width: 1200px) {
  .section-title {
    font-size: 3rem;
  }
}
.nicefont {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}
h1,
h2,
h3 {
  font-family: 'Grobold', sans-serif;
  font-weight: normal;
}
form {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
html {
  font-size: 16px;
}
body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  background: #fff;
}
.textContent {
  font-size: 1rem;
}
.outer-wrapper {
  overflow: hidden;
  max-width: 100vw;
  position: relative;
  background-color: #0491ff;
  background: -webkit-gradient(linear, left top, left bottom, from(#0491ff), to(#d3e3ec));
  background: linear-gradient(to bottom, #0491ff, #d3e3ec);
}
.outer-wrapper.outer-wrapper--alt .columns {
  margin-bottom: 50px;
}
.outer-wrapper.outer-wrapper--alt .columns--top .columns__main {
  display: block;
}
.outer-wrapper.outer-wrapper--alt .left-social-feeds-alt {
  display: block;
}
.outer-wrapper.outer-wrapper--alt .ribbon__container {
  -webkit-transform: translateX(-50%) translateY(50%);
          transform: translateX(-50%) translateY(50%);
  bottom: 0;
}
@media (min-width: 1200px) {
  .outer-wrapper.outer-wrapper--alt .columns__main,
  .outer-wrapper.outer-wrapper--alt .columns__left {
    padding-top: 30px;
  }
}
@media (max-width: 767px) {
  .productText {
    width: auto;
    float: right;
    margin-top: 10px;
  }
}
@media (min-width: 768px) {
  .productText {
    width: auto;
  }
}
.products-intro {
  display: block;
  margin: 0 auto 30px;
}
@media (max-width: 420px) {
  #PageListings > div,
  .homepage-listings-row > div {
    width: 100%;
  }
}
.container {
  position: relative;
}
@media (max-width: 767px) {
  .container {
    margin: 0 auto 0;
    padding: 5px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
    padding: 0 20px;
    margin: 0 auto 0;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
    margin: 0 auto 0;
  }
}
/*#region TopLinksMobile*/
#TopLinksMobileSpacer {
  padding-top: 53px;
}
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #f40000;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  min-height: 5px;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: block !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0% 0%;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion TopLinksMobile*/
.top-wrapper {
  position: relative;
}
.header {
  text-align: center;
  position: relative;
}
@media (max-width: 767px) {
  .header {
    padding: 30px 15px;
  }
}
@media (min-width: 768px) {
  .header {
    padding-top: 30.68%;
  }
}
@media (max-width: 767px) {
  .header__rainbow {
    display: none;
  }
}
@media (min-width: 768px) {
  .header__rainbow {
    position: absolute;
    width: 67.18%;
    bottom: -33.43%;
    left: 17.09%;
  }
}
.header__logo {
  z-index: 3;
}
@media (min-width: 768px) {
  .header__logo {
    position: absolute;
    left: 30.23%;
    width: 35.16%;
    bottom: 6%;
  }
}
@media (max-width: 767px) {
  .header__logo {
    display: block;
    margin: 0 auto 20px;
    max-width: 400px;
  }
}
@media (max-width: 767px) {
  .header__badges {
    margin: 10px auto 0;
  }
}
@media (max-width: 767px) {
  .header__bcn {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__bcn {
    position: absolute;
    width: 7.01%;
    bottom: 25.07%;
    right: 5.98%;
  }
}
@media (max-width: 767px) {
  .header__bcn {
    height: 58px;
  }
}
@media (max-width: 767px) {
  .header__tipe {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__tipe {
    position: absolute;
    width: 4.87%;
    bottom: 25.07%;
    right: 0%;
  }
}
@media (max-width: 767px) {
  .header__tipe {
    height: 58px;
  }
}
.header__social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 767px) {
  .header__social-links {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (min-width: 768px) {
  .header__social-links {
    position: absolute;
    width: 19.08%;
    bottom: 23.64%;
    right: 13.43%;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}
.header__social-link {
  border: 2px solid #fff;
  border-radius: 50%;
  margin: 0 5px;
  display: inline-block;
}
@media (max-width: 767px) {
  .header__social-link {
    width: 55px;
  }
}
@media (min-width: 768px) {
  .header__social-link {
    border: 4px solid #fff;
    margin: 0 2%;
    max-width: 32%;
  }
}
@media (max-width: 767px) {
  .header__call {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__call {
    position: absolute;
    width: 19.74%;
    bottom: 70.75%;
    right: 0.43%;
  }
}
@media (max-width: 767px) {
  .header__call {
    display: block;
  }
}
@media (max-width: 767px) {
  .header__phone {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__phone {
    position: absolute;
    width: 32.39%;
    bottom: 48.47%;
    right: 0.43%;
  }
}
@media (max-width: 767px) {
  .header__phone {
    display: block;
    margin: 10px auto 0;
    max-width: 400px;
  }
}
.header__weather {
  position: absolute;
  width: 26.58%;
  height: 58.77%;
  left: 1%;
  bottom: 28.6%;
  z-index: 3;
}
.header__weather:hover .header__weather-sun-corona {
  -webkit-transform: rotateZ(90deg) scale(1.15);
          transform: rotateZ(90deg) scale(1.15);
}
.header__weather:hover .header__weather-sun-body {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.header__weather-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80%;
}
.header__weather-sun {
  position: absolute;
  right: -2%;
  width: 50%;
  top: 4%;
}
.header__weather-text {
  position: absolute;
  bottom: 16%;
  left: 5%;
  width: 70%;
  -webkit-transform-origin: center center;
          transform-origin: center center;
}
.toplinks {
  border-bottom-right-radius: 15px;
  position: relative;
}
.toplinks__wrapper {
  background-color: #df2222;
  background: -webkit-gradient(linear, left top, left bottom, from(#df2222), to(#ec3a3a));
  background: linear-gradient(to bottom, #df2222, #ec3a3a);
  position: relative;
  z-index: 10;
}
.toplinks__wrapper:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat;
}
@supports (mix-blend-mode:color-burn) {
  .toplinks__wrapper:before {
    background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/027/waves-bg.png");
    opacity: 0.6;
    mix-blend-mode: color-burn;
    background-size: 200px;
  }
}
.toplinks__inner {
  position: relative;
  padding: 0 10px;
  z-index: 4;
}
.toplinks__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.toplinks__li {
  text-align: center;
  list-style: none;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.toplinks__li.open .dropdown-toggle:focus {
  color: #ffea3f;
}
.toplinks__link {
  font-family: 'Lato', sans-serif;
  font-weight: 700 !important;
  color: #fff;
  background: none;
  font-size: 18px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding: 20px 10px;
  font-size: 1.8vw;
  white-space: nowrap;
  display: block;
  text-transform: uppercase;
}
@media (min-width: 1200px) {
  .toplinks__link {
    font-size: 18px;
  }
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  background: #0b4fd2;
  color: #fff;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
}
.toplinks__dropdown {
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  padding: 0;
  background: #de1515;
  min-width: 100%;
}
.toplinks__dropdown-li {
  display: block;
  width: 100%;
  margin-left: 0;
  border-radius: 0;
}
.toplinks__dropdown-link {
  padding: 5px 10px !important;
  font-size: 1rem;
  color: #fff !important;
  font-family: 'Lato', sans-serif;
  font-weight: 700 !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
@media (min-width: 1200px) {
  .toplinks__dropdown-link {
    font-size: 1.2rem;
  }
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus {
  background-color: #0b4fd2 !important;
  color: #fff !important;
}
.body-wrapper {
  position: relative;
}
.ribbon {
  position: relative;
  -webkit-transform: translateY(-56%);
          transform: translateY(-56%);
}
.ribbon__container {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 2;
  display: none;
  pointer-events: none;
}
@media (min-width: 1200px) {
  .ribbon__container {
    display: block;
  }
}
.ribbon:before,
.ribbon:after {
  content: "";
  position: absolute;
  width: 4%;
  padding-top: 20%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/027/ribbon-left.png");
  top: 24%;
  left: -4%;
  background-repeat: no-repeat;
}
.ribbon:after {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/027/ribbon-right.png");
  left: auto;
  right: -4%;
  top: 27%;
}
@media (max-width: 767px) {
  .banner {
    border-radius: 10px;
  }
}
.banner__container.us-override .SearchButton {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/027/search-button-us.png");
}
.banner #CarouselContainer {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
@media (max-width: 767px) {
  .banner #CarouselContainer {
    border-radius: 10px;
  }
}
@media (min-width: 768px) {
  .banner__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}
.banner__gallery {
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.banner__gallery #CarouselContainer {
  padding-top: 56.8%;
  overflow: hidden;
}
.banner__gallery #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner__gallery #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner__gallery #CarouselContainer .carousel-inner .item a img,
.banner__gallery #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
@media (max-width: 767px) {
  .banner__gallery {
    position: relative;
  }
}
.banner__left {
  position: relative;
}
@media (min-width: 768px) {
  .banner__left {
    width: 75.21%;
  }
  .banner__left:before {
    content: "";
    width: 100%;
    padding-top: 56.82%;
    display: block;
  }
}
.banner__left-no-booking {
  position: relative;
}
@media (min-width: 768px) {
  .banner__left-no-booking {
    width: 100%;
  }
  .banner__left-no-booking:before {
    content: "";
    width: 100%;
    padding-top: 56.82%;
    display: block;
  }
}
.banner__right {
  background-color: #00aaff;
  background: #2bb2f5;
  background: radial-gradient(ellipse at center, #2bb2f5 0%, #0079df 100%);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
  -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
          box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}
@media (max-width: 767px) {
  .banner__right {
    padding: 35px 20px;
    text-align: center;
    margin-top: 10px;
    border-radius: 5px;
  }
}
@media (min-width: 768px) {
  .banner__right {
    width: 24.87%;
  }
}
.banner__right:before {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAA1NJREFUWIXFl9uSozYQhn9hzhgwPsB4Jt6X8MNvqrJbtblIKpVk8xAZn2CMDRh71zaIvUilJTm1t0F3n5BEi+7+u2GfPv64vHz5gn+HxjQwTSNmDNAYI+YdB+ecOPB9BL5PfL3dcL3diNu2Rdu2xLZtw3ZsYr2sSpyqE01MJhNEo4i4KArsDwfi8WSM6WwmDhgMAGEfRqMQtu0Q//36is1mSxyNIwx0XVwYPY/eDdAdx0EYhjRhWRZM0yAOwwC2bRG3LcfxWBAH/hCmPyQ+FiUulx1x0zSIIuHStmmRpplqwHw+VzY0zZ3Yti1o2oA4TTPspQNM00AYBsRFUeJ1tSaOZzPMZlPiLMuQZmJ/7y7o3QD9fm+w26U0YdmW4vO6PuPr1ytxByCORRp2vMNW2s85RyylqeM6im74gQ/TMmUD7silPJ9OJ7CkBfX5jDwXz2ezKeJY+HS3S5ULjMdjxeec/1e4bGl/7y7o3QD28cP7pWGIvOeco5M+maZp0AbCTsuyYFlyjNSo67M4seuUFzyub5o7mrYh1nXdQJIkNJGmKdLsjTh5ShDHMfHtdsP1KoLy8QWH/IA8z4mjKIJti+JTn884Ho/iguh59G4A++vzb8sgEFJaFiXKshQLGJRy63kePM8jLh7WG4YBU4qpf6Rd+FzTmBJTuu/7itZ7ngddF5xlGTJJuxeLBaZTWdvflOLy7t0C82dRW3bbHfb7PXEcq7Whdxf0bgD74/dflqPRiCaqqkJVVcQd5+g6oQtM06BJPWNzb9BKPh5FEaKxep7c8rVti1bSGb0oCgyHoqE4ny/IDyJPp5MxphNRXLbbHVbbFfFTkmD+JHRENwx0khh5rgtb0on1Zov1ekPcuwt6N4D9/OmnJZMSvX0on67rwHNFm32qTjidTuohkk4EYYhAatGqskIpxRQDU9brlmVi9Sp6uCiKEEUiiBiD8mPhuI7SsKRZhnQndKADg+u6xEVRYi39Fyx+eMHLyzNx7y7o3QD2+c9flwcp7TreKXnveR6GQ6H99alGXdffPbDrOnApDbuH52EYYCTFiG4YpvJjkue5ot0JY/AlnbhcLnh7k/qFJEYi9Qur9QYrKc/nz3PM50/EA00Db6WG57tX+Z9G7wZ8A0IRaqkFLAytAAAAAElFTkSuQmCC");
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat;
  background-size: 20px;
  opacity: 0.7;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.banner__right #PostcodeSearchLeft select,
.banner__right #PostcodeSearchLeft input {
  border-radius: 10px;
}
@media (max-width: 767px) {
  .banner__right #PostcodeSearchLeft select,
  .banner__right #PostcodeSearchLeft input {
    position: relative;
    margin: 0 auto 15px;
    height: 45px;
    border-radius: 5px;
    text-align: center;
    width: 240px;
  }
}
@media (min-width: 768px) {
  .banner__right #PostcodeSearchLeft select,
  .banner__right #PostcodeSearchLeft input {
    padding-left: 10%;
    position: absolute;
    top: 23.2%;
    height: 8.2%;
    left: 23.02%;
    width: 72.51%;
  }
}
.banner__right .PostcodeMessage {
  z-index: 10;
  position: fixed;
  top: 50px;
  bottom: auto;
}
@media (min-width: 768px) {
  .banner__right .PostcodeMessage {
    top: 0;
  }
}
@media (max-width: 767px) {
  .banner__right #PostcodeSearchRight a {
    height: 48px;
    background: #f40000;
    background-color: #ff3e3e;
    background: -webkit-gradient(linear, left top, left bottom, from(#ff3e3e), to(#ff0000));
    background: linear-gradient(to bottom, #ff3e3e, #ff0000);
    border-radius: 5px;
    color: #fff;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: -webkit-box;
    display: flex;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-family: 'Lato', sans-serif;
    font-weight: 700 !important;
    font-size: 1.2rem;
    border: 1px solid transparent;
    position: relative;
    width: 240px;
    margin: 0 auto 20px;
  }
  .banner__right #PostcodeSearchRight a:hover,
  .banner__right #PostcodeSearchRight a:focus {
    color: #fff;
  }
}
.banner__right .SearchDate,
.banner__right .SearchCategory {
  border-radius: 10px;
  border: none;
  z-index: 1;
}
@media (min-width: 768px) {
  .banner__right .SearchDate,
  .banner__right .SearchCategory {
    width: 82.82%;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 8.2%;
  }
}
.banner__right .SearchDate {
  padding-left: 10px;
}
@media (max-width: 767px) {
  .banner__right .SearchDate {
    display: block;
    position: relative;
    margin: 0 auto 15px;
    border-radius: 5px;
    height: 45px;
    width: 240px;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .banner__right .SearchDate {
    top: 46%;
  }
}
@media (max-width: 767px) {
  .banner__right .SearchCategory {
    display: block;
    position: relative;
    margin: 0 auto 15px;
    border-radius: 5px;
    height: 45px;
    width: 240px;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .banner__right .SearchCategory {
    top: 56%;
  }
}
.banner__right .SearchButton {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/027/search-button.png");
  background-size: 100% 100%;
  font-size: 0;
}
@media (max-width: 767px) {
  .banner__right .SearchButton {
    position: relative;
    margin: 0 auto;
    width: 200px;
  }
}
@media (min-width: 768px) {
  .banner__right .SearchButton {
    top: 66%;
    height: 8.2%;
    width: 82.82%;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@media (max-width: 767px) {
  .banner__book-online {
    display: block;
    position: relative;
    margin: 0 auto 15px;
  }
}
@media (min-width: 768px) {
  .banner__book-online {
    width: 83.16%;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 8.6%;
  }
}
.banner__map-icon {
  position: absolute;
  width: 29.55%;
  left: 12px;
  top: 16.1%;
  z-index: 1;
}
@media (max-width: 767px) {
  .banner__map-icon {
    display: none;
  }
}
@media (max-width: 767px) {
  .banner__check-area {
    display: block;
    position: relative;
    margin: 0 auto 15px;
  }
}
@media (min-width: 768px) {
  .banner__check-area {
    position: absolute;
    width: 60.82%;
    left: 34.02%;
    top: 17%;
  }
}
@media (max-width: 767px) {
  .banner__check-availability {
    position: relative;
    margin: 0 auto 15px;
  }
}
@media (min-width: 768px) {
  .banner__check-availability {
    width: 80.41%;
    top: 36.6%;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
.banner__weather {
  position: absolute;
  width: 82.76%;
  height: 21.2%;
  left: 8.9%;
  top: 76.6%;
  z-index: 3;
}
.banner__weather:hover .banner__weather-sun-corona {
  -webkit-transform: rotateZ(90deg) scale(1.15);
          transform: rotateZ(90deg) scale(1.15);
}
.banner__weather:hover .banner__weather-sun-body {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.banner__weather-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner__weather-sun-corona {
  position: absolute;
  top: 1%;
  left: 2%;
  width: 44%;
  -webkit-transition: all 3s ease-in-out;
  transition: all 3s ease-in-out;
}
.banner__weather-sun-body {
  position: absolute;
  width: 33%;
  top: 14%;
  left: 8%;
  -webkit-transition: all 3s ease-in-out;
  transition: all 3s ease-in-out;
  -webkit-transform-origin: center center;
          transform-origin: center center;
}
.banner__weather-text {
  position: absolute;
  bottom: 25%;
  right: 7%;
  width: 41%;
  -webkit-transform-origin: center center;
          transform-origin: center center;
}
.featured-categories {
  margin: 20px auto 10px;
}
@media (max-width: 420px) {
  .featured-categories__col {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .featured-categories__col {
    width: 50%;
  }
}
.featured-categories__image-panel {
  padding-top: 79.333%;
  position: relative;
}
.featured-categories img {
  width: 100%;
  max-width: none !important;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  -o-object-fit: cover;
     object-fit: cover;
}
.featured-categories__category-panel {
  position: relative;
  margin-bottom: 10px;
  display: block;
}
.columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  position: relative;
  z-index: 1;
  margin-top: 30px;
}
.columns--top {
  margin-top: 0;
}
.columns--top .columns__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-bottom: 30px;
}
.columns--top .columns__left {
  padding-bottom: 30px;
}
.columns--top .columns__left:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 10px;
  background-color: rgba(0, 0, 0, 0);
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05));
  background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.05)));
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05));
}
.columns__left {
  background: #fff;
  position: relative;
  width: 25.81%;
  z-index: 2;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  padding: 0 20px 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.columns__left.columns__left--home-listings {
  padding: 0 20px 0 0;
}
.columns__main {
  overflow: hidden;
  background: #fff;
  position: relative;
  background-size: 100% auto;
  background-position: 0 5px;
  width: 100%;
  padding: 0 15px;
  z-index: 2;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}
@media (max-width: 767px) {
  .columns__main {
    padding: 0 10px;
    margin: 0;
    border-radius: 15px;
  }
}
@media (min-width: 768px) {
  .columns__main {
    width: 74.44%;
  }
}
.columns__main-offset {
  margin: 0 15px;
  padding: 1px 15px;
  background: #fff;
}
@media (max-width: 767px) {
  .columns__main-offset {
    padding: 1px 10px;
    margin: 0;
  }
}
.category-links {
  margin-bottom: 10px;
  position: relative;
}
.category-links__link,
.category-links__child-link {
  position: relative;
  display: block;
  margin-top: 5%;
  text-align: left;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700;
  text-shadow: 1px 1px 2px #000;
  color: white;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-size: 100% 100%;
  border-radius: 25px;
  padding: 8px 15px;
  overflow: hidden;
}
.category-links__link:before,
.category-links__child-link:before {
  content: "";
  position: absolute;
  left: -2px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-left: 8px solid #fff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.category-links__link:after,
.category-links__child-link:after {
  content: "";
  position: absolute;
  border-radius: 25px;
  top: 0;
  left: 0;
  bottom: 40%;
  right: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.category-links__link:first-child,
.category-links__child-link:first-child {
  margin-top: 25px;
}
.category-links__link:nth-of-type(odd),
.category-links__child-link:nth-of-type(odd) {
  background-color: #1175e1;
  background: -webkit-gradient(linear, left top, left bottom, from(#1175e1), to(#0940c9));
  background: linear-gradient(to bottom, #1175e1, #0940c9);
}
.category-links__link:nth-of-type(odd):after,
.category-links__child-link:nth-of-type(odd):after {
  background: rgba(255, 255, 255, 0.05);
}
.category-links__link:nth-of-type(odd) + div .category-links__child-link,
.category-links__child-link:nth-of-type(odd) + div .category-links__child-link {
  background-color: #1175e1;
  background: -webkit-gradient(linear, left top, left bottom, from(#1175e1), to(#0940c9));
  background: linear-gradient(to bottom, #1175e1, #0940c9);
}
.category-links__link:nth-of-type(odd) + div .category-links__child-link:after,
.category-links__child-link:nth-of-type(odd) + div .category-links__child-link:after {
  background: rgba(255, 255, 255, 0.05);
}
.category-links__link:nth-of-type(even),
.category-links__child-link:nth-of-type(even) {
  background-color: #f40000;
  background: -webkit-gradient(linear, left top, left bottom, from(#f40000), to(#eb0000));
  background: linear-gradient(to bottom, #f40000, #eb0000);
}
.category-links__link:nth-of-type(even):after,
.category-links__child-link:nth-of-type(even):after {
  background: rgba(255, 255, 255, 0.2);
}
.category-links__link:nth-of-type(even) + div .category-links__child-link,
.category-links__child-link:nth-of-type(even) + div .category-links__child-link {
  background-color: #f40000;
  background: -webkit-gradient(linear, left top, left bottom, from(#f40000), to(#eb0000));
  background: linear-gradient(to bottom, #f40000, #eb0000);
}
.category-links__link:nth-of-type(even) + div .category-links__child-link:after,
.category-links__child-link:nth-of-type(even) + div .category-links__child-link:after {
  background: rgba(255, 255, 255, 0.2);
}
@media (min-width: 1200px) {
  .category-links__link,
  .category-links__child-link {
    font-size: 20px;
  }
}
.category-links__link:hover,
.category-links__child-link:hover,
.category-links__link:focus,
.category-links__child-link:focus,
.category-links__link.active,
.category-links__child-link.active {
  color: #fff;
  padding-left: 20px;
}
.category-links__link:hover:before,
.category-links__child-link:hover:before,
.category-links__link:focus:before,
.category-links__child-link:focus:before,
.category-links__link.active:before,
.category-links__child-link.active:before {
  opacity: 1;
}
.category-links__child-link {
  margin-left: 10px;
}
/*#region Other Left Column Stuff*/
.left-block-top {
  padding: 10% 5%;
  background-color: #0086ff;
  background: -webkit-gradient(linear, left top, left bottom, from(#0086ff), to(#004aff));
  background: linear-gradient(to bottom, #0086ff, #004aff);
  position: relative;
  border-radius: 10px;
  margin-top: auto;
}
.left-block-top:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/027/left-block-starburst.png");
  background-size: 100% 100%;
}
.left-social-feeds-alt {
  display: none;
}
.left-block-lower {
  padding: 10% 5%;
  background-color: #0086ff;
  background: -webkit-gradient(linear, left top, left bottom, from(#0086ff), to(#004aff));
  background: linear-gradient(to bottom, #0086ff, #004aff);
  position: relative;
  border-radius: 5px;
  margin-top: 10px;
}
.left-block-lower:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/027/left-block-starburst.png");
  background-size: 100% 100%;
}
/*#endregion Other Left Column Stuff*/
.homepage-listings {
  padding-bottom: 160px;
}
.homepage-listings__sidebar {
  background: #fff;
  border-radius: 10px;
  padding: 8px;
}
.homepage-listings .columns {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.homepage-listings .columns__left {
  background: transparent;
}
.homepage-listings .columns__main {
  background: transparent;
}
.newsletter {
  padding-top: 14.3%;
  background-color: #0e66df;
  background: -webkit-gradient(linear, left top, left bottom, from(#0e66df), to(#0835bd));
  background: linear-gradient(to bottom, #0e66df, #0835bd);
  border-radius: 10px;
  position: relative;
  text-align: center;
  margin-top: auto;
}
.newsletter:before {
  border-radius: 10px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat;
  opacity: 0.5;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOAgMAAABiJsVCAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAARnQU1BAACxjwv8YQUAAAABc1JHQgCuzhzpAAAADFBMVEUAAAAAAAAAAAAAAAA16TeWAAAABHRSTlMADQcaCWiadAAAAChJREFUCNdjuMDAIMAQxsCgwCDLAASqCUCCESjIwBQG4hMWTGBgCgAA8yMHEmDIg7sAAAAASUVORK5CYII=");
}
@media (max-width: 767px) {
  .newsletter {
    padding: 20px;
  }
}
.newsletter__text {
  display: block;
  margin: 0 auto;
  position: relative;
}
@media (min-width: 768px) {
  .newsletter__text {
    width: 33.33%;
    left: 1.9%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: 0.5%;
  }
}
.newsletter input {
  border-radius: 5px;
  padding-left: 2%;
  border: none;
  -webkit-box-shadow: 0 2px 3px rgba(255, 255, 255, 0.5);
          box-shadow: 0 2px 3px rgba(255, 255, 255, 0.5);
  min-height: 40px;
  display: block;
  width: 100%;
  max-width: 410px;
  position: relative;
}
@media (max-width: 767px) {
  .newsletter input {
    text-align: center;
    margin: 20px auto 0;
  }
}
@media (min-width: 768px) {
  .newsletter input {
    width: 39.64%;
    left: 38.21%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 45%;
  }
}
.newsletter .NewsletterSubmit {
  background-color: #d62c2c;
  background: -webkit-gradient(linear, left top, left bottom, from(#d62c2c), to(#b20808));
  background: linear-gradient(to bottom, #d62c2c, #b20808);
  text-shadow: 0 1px #000;
  border-radius: 10px;
  color: #fff;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0 2px 3px rgba(255, 255, 255, 0.5);
          box-shadow: 0 2px 3px rgba(255, 255, 255, 0.5);
  font-size: 5vw;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 0;
  position: relative;
}
.newsletter .NewsletterSubmit:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 40%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
}
.newsletter .NewsletterSubmit:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/027/subscribe.png");
  background-size: 67%;
}
@media (max-width: 767px) {
  .newsletter .NewsletterSubmit {
    margin-top: 20px;
    padding: 5px 20px;
    display: inline-block;
    width: 215px;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .newsletter .NewsletterSubmit {
    height: 45%;
    width: 19.05%;
    left: 78.69%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
.newsletter .NewsletterSubmit:hover {
  color: #ffe421;
  -webkit-box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
          box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}
.testimonials {
  background: #0072ff;
  text-align: center;
  position: relative;
  border: 10px solid #fff;
  border-radius: 10px;
  margin-bottom: 50px;
  overflow: hidden;
  padding: 20px 5%;
  -webkit-box-shadow: inset 0px 0px 10px 10px rgba(0, 0, 0, 0.15);
          box-shadow: inset 0px 0px 10px 10px rgba(0, 0, 0, 0.15);
}
@media (min-width: 1200px) {
  .testimonials {
    padding: 50px 5%;
  }
}
.testimonials:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAA1NJREFUWIXFl9uSozYQhn9hzhgwPsB4Jt6X8MNvqrJbtblIKpVk8xAZn2CMDRh71zaIvUilJTm1t0F3n5BEi+7+u2GfPv64vHz5gn+HxjQwTSNmDNAYI+YdB+ecOPB9BL5PfL3dcL3diNu2Rdu2xLZtw3ZsYr2sSpyqE01MJhNEo4i4KArsDwfi8WSM6WwmDhgMAGEfRqMQtu0Q//36is1mSxyNIwx0XVwYPY/eDdAdx0EYhjRhWRZM0yAOwwC2bRG3LcfxWBAH/hCmPyQ+FiUulx1x0zSIIuHStmmRpplqwHw+VzY0zZ3Yti1o2oA4TTPspQNM00AYBsRFUeJ1tSaOZzPMZlPiLMuQZmJ/7y7o3QD9fm+w26U0YdmW4vO6PuPr1ytxByCORRp2vMNW2s85RyylqeM6im74gQ/TMmUD7silPJ9OJ7CkBfX5jDwXz2ezKeJY+HS3S5ULjMdjxeec/1e4bGl/7y7o3QD28cP7pWGIvOeco5M+maZp0AbCTsuyYFlyjNSo67M4seuUFzyub5o7mrYh1nXdQJIkNJGmKdLsjTh5ShDHMfHtdsP1KoLy8QWH/IA8z4mjKIJti+JTn884Ho/iguh59G4A++vzb8sgEFJaFiXKshQLGJRy63kePM8jLh7WG4YBU4qpf6Rd+FzTmBJTuu/7itZ7ngddF5xlGTJJuxeLBaZTWdvflOLy7t0C82dRW3bbHfb7PXEcq7Whdxf0bgD74/dflqPRiCaqqkJVVcQd5+g6oQtM06BJPWNzb9BKPh5FEaKxep7c8rVti1bSGb0oCgyHoqE4ny/IDyJPp5MxphNRXLbbHVbbFfFTkmD+JHRENwx0khh5rgtb0on1Zov1ekPcuwt6N4D9/OmnJZMSvX0on67rwHNFm32qTjidTuohkk4EYYhAatGqskIpxRQDU9brlmVi9Sp6uCiKEEUiiBiD8mPhuI7SsKRZhnQndKADg+u6xEVRYi39Fyx+eMHLyzNx7y7o3QD2+c9flwcp7TreKXnveR6GQ6H99alGXdffPbDrOnApDbuH52EYYCTFiG4YpvJjkue5ot0JY/AlnbhcLnh7k/qFJEYi9Qur9QYrKc/nz3PM50/EA00Db6WG57tX+Z9G7wZ8A0IRaqkFLAytAAAAAElFTkSuQmCC') /*little-plusses.png*/;
}
.testimonials__containers {
  margin-top: -50px;
}
.testimonials__container {
  margin: 10px;
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 27px 10px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 27px 10px rgba(0, 0, 0, 0.15);
}
.testimonials__title {
  position: relative;
  margin-top: 0;
}
@media (min-width: 1200px) {
  .testimonials__title {
    font-size: 38px;
  }
}
.testimonials__stars {
  position: relative;
  -webkit-transform: translateY(-70px);
          transform: translateY(-70px);
  z-index: 1;
}
.testimonials__stars:before,
.testimonials__stars:after {
  content: "";
  position: absolute;
  width: 30%;
  padding-top: 16%;
  top: 0;
  background-image: url(https://files.bookingonline.co.uk/image/upload/f_auto/themes/027/testimonial-stars@1x.png);
  background-size: 100% 100%;
  z-index: 1;
  background-repeat: no-repeat;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .testimonials__stars:before,
  .testimonials__stars:after {
    background-image: url(https://files.bookingonline.co.uk/image/upload/f_auto/themes/027/testimonial-stars@2x.png);
  }
}
.testimonials__stars:before {
  left: -4%;
}
.testimonials__stars:after {
  right: -4%;
}
.testimonials__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}
.testimonials__text {
  color: #808080;
  margin-bottom: 10px;
  font-size: 1rem;
}
.testimonials__author {
  color: #ff007d;
  font-family: 'Komikatitle', sans-serif;
  font-weight: 400;
  padding: 0 10px;
  font-size: 1.25rem;
}
/*#region Castle Panels*/
.castlePanel {
  width: 100%;
  border: none;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  margin: 0 0 30px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  border: 2px solid #ccc;
}
.castlePanel:hover {
  -webkit-box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
          box-shadow: 5px 5px 6px 0 rgba(0, 0, 0, 0.075);
}
.castlePanel:hover .castleCheckBook {
  color: yellow;
}
.castlePanel .SingleImagePanel img {
  width: 100%;
  height: 100%;
  top: 0 !important;
  left: 0 !important;
  -o-object-fit: cover;
     object-fit: cover;
}
.castlePanel .castleTitlePanel {
  margin-top: 15px;
  padding: 0 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castleTitlePanel a {
  font-family: 'Lato', sans-serif;
  font-weight: 700 !important;
  color: #4c4c4c;
  font-size: 1rem;
  position: relative;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  color: #4c4c4c;
  font-family: 'Lato', sans-serif;
  font-weight: 700 !important;
  font-size: 1.2rem;
  position: relative;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice {
  color: #ff0000;
  font-size: 20px;
}
.castlePanel .castlePriceDefault span.newprice,
.castlePanel .castlePriceDiscounted span.newprice {
  font-size: 24px;
  color: #4c4c4c;
}
.castlePanel .castleCheckBook {
  position: relative;
  border-radius: 3px;
  color: #fff;
  font-size: 18px;
  background-color: #eb3636;
  background: -webkit-gradient(linear, left top, left bottom, from(#eb3636), to(#d52424));
  background: linear-gradient(to bottom, #eb3636, #d52424);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
  height: auto;
  padding: 10px 20px;
  display: block;
  font-family: 'Komikatitle', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}
/*#endregion Castle Panels*/
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #0940c9;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: 195px;
  display: inline-block;
  margin: 20px 5px 10px;
  float: none;
}
.home-gallery {
  background: #fff;
  display: block;
  position: relative;
  padding: 20px 0 15px;
  background: transparent;
}
.home-gallery:before,
.home-gallery:after {
  content: "";
  position: absolute;
  width: calc( (100vw - 1200px)/2);
  top: 0;
  bottom: 0;
}
.home-gallery:before {
  left: 0;
  z-index: 1;
  background-color: #0b4dd0;
  background-image: -webkit-linear-gradient(left, #0b4dd0, rgba(11, 77, 208, 0));
  background: -webkit-gradient(linear, left top, right top, from(#0b4dd0), to(rgba(11, 77, 208, 0)));
  background: linear-gradient(to right, #0b4dd0, rgba(11, 77, 208, 0));
}
.home-gallery:after {
  right: 0;
  -webkit-mask-image: -webkit-gradient(linear, right top, left top, color-stop(20%, #000000), to(rgba(0, 0, 0, 0)));
  -webkit-mask-image: linear-gradient(-90deg, #000000 20%, rgba(0, 0, 0, 0) 100%);
          mask-image: -webkit-gradient(linear, right top, left top, color-stop(20%, #000000), to(rgba(0, 0, 0, 0)));
          mask-image: linear-gradient(-90deg, #000000 20%, rgba(0, 0, 0, 0) 100%);
  background-position-x: calc(((100vw - 1200px)/2) - 100vw + 3px);
  background-color: rgba(11, 77, 208, 0);
  background-image: -webkit-linear-gradient(left, rgba(11, 77, 208, 0), #0b4dd0);
  background: -webkit-gradient(linear, left top, right top, from(rgba(11, 77, 208, 0)), to(#0b4dd0));
  background: linear-gradient(to right, rgba(11, 77, 208, 0), #0b4dd0);
}
.home-gallery__wrapper {
  background-color: #0b4dd0;
}
.home-gallery__container {
  background: #fff;
}
.home-gallery__slide {
  margin: 0 10px;
}
.home-gallery__link {
  display: block;
  border-radius: 5px;
  overflow: hidden;
  padding: 0;
  margin: 0 10px;
  border: 5px solid #fff !important;
  border-radius: 0 !important;
  background-color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, 0.3);
  padding: 1% 1% 63% !important;
}
.home-gallery__link:hover {
  border-color: #ababab;
}
.home-gallery__link img {
  left: 0 !important;
  top: 0 !important;
}
.footer__wrapper {
  background-color: #000;
  color: #fff;
  padding: 25px 10px;
  width: 100%;
  z-index: 1;
  position: relative;
}
@media (max-width: 767px) {
  .footer__wrapper {
    text-align: center;
  }
}
.footer__links {
  text-align: center;
}
@media (max-width: 767px) {
  .footer__links {
    text-align: center;
    margin-top: 15px;
  }
}
.footer__links a {
  color: white;
  margin-right: 10px;
}
.footer__BCN {
  display: block;
  max-width: 180px;
}
@media (max-width: 767px) {
  .footer__BCN {
    text-align: center;
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .footer__BCN {
    float: right;
  }
}
.footer__BCN img {
  margin: 0 auto;
}
#CategoryPagination > li > a {
  width: 42px;
}
/*#region Animations*/
.wow {
  visibility: hidden;
}
.fadeIn2 {
  -webkit-animation-name: fadeIn2;
          animation-name: fadeIn2;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  -webkit-animation-name: zoomIn;
          animation-name: zoomIn;
}
@-webkit-keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
            transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
            transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
            transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
            transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
.bounceIn {
  -webkit-animation-name: bounceIn;
          animation-name: bounceIn;
}
.animated.bounceIn {
  -webkit-animation-duration: 0.75s;
          animation-duration: 0.75s;
}
/*#endregion Animations*/
